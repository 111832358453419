export default {
  data() {
    return {
      applicant: {
        user: {},
        profession: {},
        education: {},
        experticies: [
          {
            specialization: {},
            subcategory: [
              {
                area: {},
                level: {},
                softwares: [],
              },
            ],
          },
        ],
      },
    };
  },
};
