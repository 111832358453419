<template>
  <div>
    <h3>Education</h3>
    <hr />
    <div
      v-if="educations"
      class="basic-information__demographics-container"
    >
      <div
        v-if="educations.high_school"
        class="basic-information__demographics-item"
      >
        <h5 class="basic-information__h5">High school:</h5>
        <p>{{ educations.high_school }}</p>
      </div>

      <div
        v-if="educations.bachelor_degree"
        class="basic-information__demographics-item"
      >
        <h5 class="basic-information__h5">Bachelor degree:</h5>
        <p>{{ educations.bachelor_degree }}</p>
      </div>
      <div
        v-if="educations.master"
        class="basic-information__demographics-item"
      >
        <h5 class="basic-information__h5">Master:</h5>
        <p>{{ educations.master }}</p>
      </div>

      <div
        v-if="educations.phd"
        class="basic-information__demographics-item"
      >
        <h5 class="basic-information__h5">Phd:</h5>
        <p>{{ educations.phd }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EducationInfoCard",
  props: {
    educations: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.basic-information {
  grid-column: 1;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__demographics-container {
    width: 100%;
  }
  &__demographics-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__h4 {
    margin: 1rem 0;
    align-self: flex-start;
  }
  &__h5 {
    margin: 15px;
    color: gray;
  }
}
</style>
