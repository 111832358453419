<template>
    <DataTable :value="expertise" :expandedRows.sync="expandedRows" dataKey="id"
      responsiveLayout="scroll">
      <Column :expander="true" :headerStyle="{'width': '3rem'}" />
      <Column field="specialization.name" header="Specialization"></Column>
      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable :value="slotProps.data.subcategory">
              <Column field="area.name" header="Area"></Column>
              <Column header="Experience">
                <template #body="slotProps">
                  <p>{{slotProps.data.experience}} years</p>
                </template>
              </Column>
              <Column field="sector" header="Sector"></Column>
              <Column field="level.name" header="Level"></Column>
              <Column header="Software/s">
                <template #body="slotProps">
                  <div class="software-list">
                    <p v-for="(option, index) in slotProps.data.softwares"
                    :key="index">{{option.software.name}}</p>
                  </div>
                </template>
              </Column>
              <Column  header="State" :headerStyle="{'width':'4rem'}">
                <template #body="slotProps">
                  <i v-if="slotProps.data.approved === true" class="pi pi-check"></i>
                  <i v-if="slotProps.data.approved === false" class="pi pi-times"></i>
                  <div v-if="slotProps.data.approved === null && $route.params.access !== 'freelancer'">
                    <button class="accept-button" @click="alert(slotProps.data.id, true)">Accept</button>
                    <button class="reject-button" @click="alert(slotProps.data.id, false)">Reject</button>
                  </div>
                  <p v-if="slotProps.data.approved === null && $route.params.access === 'freelancer'">
                    Pending
                  </p>
                </template>
              </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
</template>

<script>
export default {
  name: 'InfoCard',
  props: {
    expertise: Array,
  },
  data() {
    return {
      expandedRows: [],
    };
  },
  methods: {
    alert(idArea, decision) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'green',
        cancelButtonColor: '#d33',
        confirmButtonText: 'I am sure',
      }).then((result) => {
        if (result.isConfirmed) {
          this.evaluate(idArea, decision);
        }
      });
    },
    async evaluate(idArea, decision) {
      const payload = {};
      payload.id = idArea;
      payload.approved = decision;
      const response = await this.$store.dispatch(
        'evaluator/evaluateFreelancer', payload,
      );
      if (response.status !== 200 && response.status !== 204) {
        this.$toast.add({
          severity: 'error',
          summary: response,
          detail: 'Error evaluating freelancer',
          life: 4000,
        });
      } else {
        this.$toast.add({
          severity: 'success',
          summary: 'Evaluation saved',
          detail: 'Area evaluated successfully',
          life: 4000,
        });
        this.$router.go();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .p-datatable-wrapper {
  min-width: 25rem;
  border-radius: $border-radius-sm;
}
.accept-button {
  @include secondary-button($color: green);
  font-size: .8rem;
  min-height: 0.7rem;
  min-width: 1rem;
  padding: 0.3rem .8rem;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 960px) {
  .accept-button {
    margin-bottom: 0;
  }

}
.reject-button {
  @include secondary-button($color: red);
  font-size: .8rem;
  min-height: 0.7rem;
  min-width: 1rem;
  padding: 0.3rem .8rem;
}

.pi-check {
  color: green;
}

.pi-times {
  color: red;
}
</style>
