<template>
  <main class="applicant"  >
    <button class="applicant__back-button"
    @click="clickOnBack()">Back</button>
    <Loading v-if="isLoading" />
    <div class="applicant"  v-if="!isLoading">
    <section class="basic-information card-element">
      <img
        :src="photo"
        alt="Profile photo"
        class="basic-information__profile-photo"
      />
      <h1>
        {{
          `${applicant.user.first_name}
                 ${applicant.user.second_name}
                 ${applicant.user.last_name}`
        }}
      </h1>
      <hr style="width: 10rem;"/>
      <h4 class="basic-information">{{ applicant.profession.name }}</h4>
      <h5>{{ applicant.user.citizenship }}</h5>
      <h4>{{ applicant.user.email }}</h4>
      <div class="basic-information__demographics-item">
        <h5 class="basic-information__h5">DNI:</h5>
        <h5>{{ applicant.dni }}</h5>
      </div>
      <div class="basic-information__demographics-item">
        <h5 class="basic-information__h5">Birth date:</h5>
        <h5>{{ applicant.birth_date }}</h5>
      </div>
        <div class="basic-information__demographics-item">
        <h5 class="basic-information__h5">Contact phone:</h5>
        <h5>{{ applicant.contact_phone }}</h5>
      </div>
    </section>
    <section v-if="infoComplete" class="info-cards">
      <InfoCard :expertise="applicant.experticies" />
    </section>
    <section  class="resume-information card-element">
       <ResumeInfo :applicant="applicant" :resume="resume"/>
    </section>
    <section  class="working-information card-element">
       <EducationInfoCard :educations="applicant.education" />
    </section>
    </div>
  </main>
</template>

<script>
import experticiesOptions from '../../utils/experticiesOptions';
import InfoCard from './components/InfoCard/Index.vue';
import EducationInfoCard from './components/InfoCard/EducationInfo/Index.vue';
import Loading from "../../components/Loading.vue";
import ResumeInfo from './components/InfoCard/ResumeInfo/ResumeInfo.vue';

export default {
  name: 'Applicant',
  components: {
    InfoCard, EducationInfoCard, Loading, ResumeInfo,
  },
  mixins: [experticiesOptions],
  data() {
    return {
      photo: null,
      infoComplete: false,
      isLoading: false,
      resume: {},
    };
  },
  async created() {
    if (this.$route.params.access === 'freelancer') {
      await this.getProfileFreelancer();
    } else {
      await this.getProfileEvaluator();
    }
    await this.getPhoto(this.applicant.user.photo);
    if (this.applicant.resumen !== null) {
      await this.getResume(this.applicant.resumen);
    }
  },
  methods: {
    async getPhoto(path) {
      this.photo = await this.$store.dispatch("freelancer/getPhoto", path);
    },
    async getResume(path) {
      this.resume = await this.$store.dispatch("freelancer/getResume", path);
    },
    async getProfileEvaluator() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        'evaluator/getProfileById',
        this.$route.params.id,
      );
      if (response.status === 200) {
        this.applicant = response.data;
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Network Error',
          detail: 'Failed to get account data!',
          life: 4000,
        });
      }
      this.infoComplete = true;
      this.isLoading = false;
    },
    async getProfileFreelancer() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        'user/getProfile',
        this.$route.params.id,
      );
      if (response.status === 200) {
        this.applicant = response.data;
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Network Error',
          detail: 'Failed to get account data!',
          life: 4000,
        });
      }
      this.infoComplete = true;
      this.isLoading = false;
    },
    clickOnBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.applicant {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr 1fr;
  grid-gap: 1rem;
  padding: 3rem;
  min-height: 110%;
  &__back-button {
    @include primary-button();
    height: 8px;
    position: fixed;
    bottom: 40px;
    left: 40px;
    transition: all 300ms ease 0ms;
    z-index: 99;
  }
  //on mobile, the basic information section should be full width
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
}
.basic-information {
  grid-column: 1;
  grid-row: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__demographics-container {
    width: 100%;
  }
  &__demographics-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__h4 {
    margin: 1rem 0;
    align-self: flex-start;
  }
  &__h5 {
    margin: 15px;
    color: gray;
  }
  &__profile-photo {
    width: 40%;
    height: auto;
    border-radius: 50%;
    border: 5px solid white;
    outline: 3px solid $primary-color;
  }
}
.info-cards {
  grid-column: 2;
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-auto-rows: fit-content(100%);
  grid-gap: 1rem;
  width: 40em;
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow;
}
.resume-information {
  display: grid;
  grid-column: 2;
  grid-row: 2;
}
.working-information {
  grid-column: 2;
  grid-row: 3;
}
.card-element {
  border-radius: $border-radius-sm;
  box-shadow: $box-shadow;
  padding: 2rem;
  background-color: white;
}
</style>
