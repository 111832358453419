<template>
  <div>
    <h3>Resume</h3>
    <hr />
    <div v-if="resume.data" class="basic-information">
        <h5 class="basic-information__h5">File:</h5>
        <button class="basic-information__secondary-button" @click="downloadFile(resume.data)">Download</button>
    </div>
    <div v-if="applicant.external_link !== ''" class="basic-information">
        <h5 class="basic-information__h5">External link:</h5>
        <p>{{applicant.external_link}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EducationInfoCard",
  props: {
    applicant: Object,
    resume: Object,
  },
  data() {
    return {
    };
  },
  methods: {
    downloadFile(file) {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `${this.resume.headers.filename}`);
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-information {
  display: flex;
  align-items: center;
  justify-content: center;
  &__h5 {
    margin: 15px;
    color: gray;
  }
  &__secondary-button {
    @include secondary-button();
    font-size: 0.7rem;
    min-height: 1.5rem;
    min-width: 5rem;
    max-height: 25px;
  }
}
</style>
